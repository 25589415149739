import React from 'react'
import { Link, graphql } from 'gatsby'
import { getImage } from "gatsby-plugin-image"
import { BgImage } from "gbimage-bridge"

//fontawsome icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTwitter,
  faLinkedinIn,
  faFacebookF,
} from "@fortawesome/free-brands-svg-icons";

//components
import Layout from "../../components/layout";
import Seo from "../../components/seo";
import StayLoop from "../../components/Home/stayloop";
import MoreArticles from "../../components/Home/morearticles";

const BlogSingle = (props) => {
  const { blog: post } = props.data
  const SEOdata = props.data.awardpagedata.edges[0].node.frontmatter?.seo_metadata
  const Schemadata = props.data.awardpagedata.edges[0].node.frontmatter?.Schema
  const Hreflang = props.data.awardpagedata.edges[0].node.frontmatter?.Hreflang
  const { edges: morepost } = props.data.moreArticles
  return (
    <Layout>
      <Seo
        title={SEOdata ? SEOdata.seo_title : "Main Principles Of The ECommerce Web Design"}
        description={SEOdata ? SEOdata.seo_description : ""}
        keywords={SEOdata ? SEOdata.seo_keyword : ""}
        image={SEOdata ? SEOdata.seo_Image?.childImageSharp?.gatsbyImageData?.images?.fallback?.src : ''}
        single_image={SEOdata ? SEOdata.seo_Single_Image : ''}
        currentPageUrlPath={props.location.pathname}
        image_format={SEOdata ? SEOdata.image_format : ""}
        image_height={SEOdata ? SEOdata.feature_image_height : ""}
        img_width={SEOdata ? SEOdata.img_width : ""}
        facebook_url={SEOdata ? SEOdata.facebook_url : ""}
        twitter_page_username={SEOdata ? SEOdata.twitter_page_username : ""}
        twitter_image_alt_tag={SEOdata ? SEOdata.twitter_image_alt_tag : ""}
        twitter_estimated_reading_lable={SEOdata ? SEOdata.twitter_estimated_reading_lable : ""}
        twitter_estimated_reading_time={SEOdata ? SEOdata.twitter_estimated_reading_time : ""}
        page_publish_date={SEOdata ? SEOdata.page_publish_date : ""}
        page_last_modified_time={SEOdata ? SEOdata.page_last_modified_time : ""}
        sitelinks_schema={Schemadata ? Schemadata.sitelinks_schema : ""}
        breadcrumb_schema={Schemadata ? Schemadata.breadcrumb_schema : ""}
        logo_schema={Schemadata ? Schemadata.logo_schema : ""}
        organization_schema={Schemadata ? Schemadata.organization_schema : ""}
        aggregate_rating_schema={Schemadata ? Schemadata.aggregate_rating_schema : ""}
        local_business_schema={Schemadata ? Schemadata.local_business_schema : ""}
        site_navigation_schema={Schemadata ? Schemadata.site_navigation_schema : ""}
        speakable_specification_schema={Schemadata ? Schemadata.speakable_specification_schema : ""}
        hreflang1={Hreflang ? Hreflang.hreflang_1 : ""}
        hreflang2={Hreflang ? Hreflang.hreflang_2 : ""}
        hreflang3={Hreflang ? Hreflang.hreflang_3 : ""}
        hreflang4={Hreflang ? Hreflang.hreflang_4 : ""}
      />


      {/* blog description section */}

      <section className="mt-60 lg:mt-20 pt-6-6 pb-35 lg:pt-51 lg:pb-59">
        <div className="container mx-auto">
          <div className="flex flex-wrap justify-between">
            <div className="hidden xl:block w-full lg:w-1/6"></div>
            <div className="w-full lg:w-4/5 xl:w-2/3">
              <h2 className="mb-3-3 lg:mb-6-3 lg:mb-1-3 text-rh2 sm:text-h3 lg:text-h2 font-poppings-bold text-term-primary">{post.frontmatter.title}</h2>
              <div className="blog-inner--cnt text-p4 lg:text-p2 font-worksans-normal text-term-primary"><h2>E-Commerce Web Design Essentials</h2>
                <p>The global marketplace and large-scale investments form the foundation for e-commerce products. So how does a startup find its place and how should you present a product to make it successful? If you are selling an e-commerce product or simply want to know about this field, read this guide on e-commerce web design.</p>
                <h2>Four keys to e-commerce selling</h2>
                <p>E-commerce consists of retail transactions that are carried out online. This means whenever you buy or sell something over the internet, you are engaging in e-commerce. The principles listed here for building a product online are universal for anyone selling goods but are they essential for e-commerce products. They are: market, traffic, conversion and service.</p>
                <ul>
                  <li>Market</li>
                </ul>
                <p>This factor varies from location to location and based on the products presented on the website. If we categorize it, most of the market is B2B, more than 38% to be exact.</p>
                <ul>
                  <li>Traffic</li>
                </ul>
                <p>Traffic is equal to the number of clients who are frequenting your virtual store and potentially ready to pay you money. For your income, you need to establish a platform to attract this attention.</p>
                <p>Attracting traffic is the main task when it comes to promoting products on the Internet. To gain a purchase, we must first draw a visitor to the site, direct it to the pages where products reside and keep track of their actions until the sale is complete. This requires the correct UX and elements to retain the user.</p>
                <ul>
                  <li>Conversion</li>
                </ul>
                <p>Let’s first take a look at the definition before we continue. Conversion is the ratio of visitors to your site who have performed a targeted action, such as purchases, to the total number of visitors.</p>
                <p>You can increase your conversion rate in many ways, but here are 3 main
                  methods are:</p>
                <ol>
                  <li>contextual advertising;</li>
                  <li>landing page;</li>
                  <li>product informers.</li>
                </ol>
                <ul>
                  <li>Service</li>
                </ul>
                <p>Regardless of how advanced your communication is with your customers, you have to communicate with people and use the correct attitude. We’d like to offer a few simple rules for successful cooperation:</p>
                <ol>
                  <li>Respect</li>
                  <li>Attention</li>
                  <li>Accuracy of information</li>
                  <li>Assistance</li>
                </ol>
                <p>Now that we generally reviewed these indicators, let’s move on to the topic of products and their creation.</p>
                <h2>Display Your Product</h2>
                <p>For any business looking to enter the world of ecommerce it’s essential that you know the leaders in your niche and why they are trusted.</p>
                <h2>Shopify &amp; Shopify Plus:</h2>
                <p>This is easily one of the most popular commercial Internet selling platforms. Shopify is simple and allows you to launch a new store very quickly.</p>
                <p>Depending on the features you need and your amount of orders, the monthly tax rate may increase or decrease.</p>
                <p>This is a good basic option for sellers who need only a few settings, functions and simple design.</p>
                <h2>WordPress</h2>
                <p>This platform was originally meant for blogging but over time has grown to
                  power a large number of plugins that significantly expanded its capabilities for
                  product creation. It stands out because it’s extremely accessible. Be sure to
                  note that when you are thinking about e-commerce platforms you should
                  consider functionality.</p>
                <h2>Squarespace</h2>
                <p>This clean, modern platform is worth your attention. It has beautiful templates
                  for building great websites and features advanced tools and pricing which
                  make Squarespace a competitive market player.</p>
                <h2>Which One Should I Choose?</h2>
                <p>In summary, we suggest comparing some common success factors: flexible
                  pay systems, beautiful design, reasonable pricing, security and seamless
                  integration.</p>
                <h2>Bonus: 2021 e-commerce Trends</h2>
                <p>A successful product must be up-to-date–that’s the main task. To achieve this,
                  determine the most relevant trends. Here are 5 of our top trends: inclusiveness, dark mode, content-oriented design, mobile-first and branding.</p>
                <h2>Inclusivity</h2>
                <p>There’s no benefit in making a product accessible and easy to understand if it’s not inclusive. To make a product inclusive, create a product that works for both healthy people and those living with disabilities. And yes, we are talking about web design!</p>
                <h2>Dark Mode</h2>
                <p>Creating interfaces in dark mode is a recent feature but has grown quickly. In addition to aesthetics, the dark mode has its mix of pros and cons:</p>
                <ol>
                  <li>Dark pixels are inactive so the device battery will run out slower;</li>
                  <li>High contrast can be used very beneficially;</li>
                  <li>Attention is pulled to the right places;</li>
                  <li>Not all text is readable against this background.</li>
                </ol>
                <h2>Content-oriented design</h2>
                <p>This design approach is based on the content, essentially highlighting what you want to show. This is for websites that have information and content such as text blocks, infographics, etc. When it comes to this kind of design, the most important focus is the arrangement of elements so the product(s) is clear and easy to understand.</p>
                <h2>Mobile-First Design</h2>
                <p>People are addicted to their phones, it’s a fact. If search engines rank products higher, this is an added bonus and will be easy for users to find your product on their mobile device. Mobile-based design means ease of use, better download speed and full functionality, all of which users love.</p>
                <h2>Prioritize Branding</h2>
                <p>A brand is an introduction to your product and your philosophy. Trust us, this matters in e-commerce.</p>
                <p>When we display a product to the public, it has to be brief, direct and multifunctional. And all of these elements must work together.</p>
                <p>Do you notify users when their favorite products are running low? Do you have a specific design for your email newsletters? What about a banner on your site? It doesn’t have to be fancy but it should display your name and make your services clear.</p>
                <h2>In Summary</h2>
                <p>Anyone looking to build a startup in e-commerce is going to face tons of information from everyone including competitors, market experts and amount of information from market experts and competitors about which design is preferable and more. In this article, we dealt with the major questions new entrepreneurs in e-commerce have and tips to help you make the best decisions.</p></div>
            </div>
            <div className="w-full lg:w-1/6">
              <div className="w-full lg:w-3/4 text-left lg:text-right flex flex-wrap lg:flex-col justify-between">
                <div className="lg:mb-39">
                  <h5 className="lg:mb-1-1 uppercase text-cyan font-poppings-bold text-rh6 md:text-h6">
                    {post.frontmatter.blog_category}
                  </h5>
                  <p className="text-term-primary font-worksans-normal text-p4 lg:text-p3">
                    {post.frontmatter.date}
                  </p>
                </div>
                <div className="flex items-center lg:items-end lg:flex-col ">
                  <Link to={post.frontmatter.twitter} target="_blank" className="text-right opacity-20 hover:opacity-100 transform transition duration-500 ease">
                    <FontAwesomeIcon
                      className="font-awesome text-2xl text-term-primary "
                      icon={faTwitter}
                    />
                  </Link>
                  <Link to={post.frontmatter.linkedin} target="_blank" className="text-right opacity-20 hover:opacity-100 transform transition duration-500 ease">
                    <FontAwesomeIcon
                      className="mx-8 lg:mx-0 lg:my-5-1 font-awesome text-2xl text-term-primary "
                      icon={faLinkedinIn}
                    />
                  </Link>
                  <Link to={post.frontmatter.facebook} target="_blank" className="text-right opacity-20 hover:opacity-100 transform transition duration-500 ease">
                    <FontAwesomeIcon
                      className="font-awesome text-2xl text-term-primary"
                      icon={faFacebookF}
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* more articles section */}
      {morepost.length &&
        <section className="pb-50 lg:pb-1-04">
          <MoreArticles articles={morepost} />
        </section>
      }

      {/* stay in loop section */}
      <StayLoop />

    </Layout>

  )
}

export default BlogSingle

export const BlogPageQuery = graphql`
  query BlogPage6($id: String!) {
    blog: markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        title
        blog_category
        templateKey
        facebook
        linkedin
        twitter
        date(formatString: "DD MMM, YYYY")
        
      }
    }
    moreArticles: allMarkdownRemark(
      filter: { 
              id: { ne: $id },
              frontmatter: { templateKey: { eq: "single-blog" } }
            }
      ) {
       edges {
         node {
           id
           fields {
             slug
           }
           frontmatter {
             title
             blog_category
             templateKey
             featured_image {              
              image {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: BLURRED
                  )
                }
              }
            }
             date(formatString: "DD MMM, YYYY")
           }
         }
       }
     }
     awardpagedata: allMarkdownRemark(filter: {id: {eq: $id}}) {
      edges {
        node {
          frontmatter {
            Hreflang {
              hreflang_1
              hreflang_3
              hreflang_2
              hreflang_4
            }
            Schema {
              aggregate_rating_schema
              breadcrumb_schema
              local_business_schema
              logo_schema
              organization_schema
              site_navigation_schema
              sitelinks_schema
              speakable_specification_schema
            }
            seo_metadata {
              facebook_url
              feature_image_height
              image_format
              img_width
              page_last_modified_time
              page_publish_date
              seo_description
              seo_keyword
              seo_title
              twitter_estimated_reading_lable
              twitter_estimated_reading_time
              twitter_image_alt_tag
              twitter_page_username
              seo_Image {
                childImageSharp {
                  gatsbyImageData(layout: FIXED, width: 1200)
                }
              }
              seo_Single_Image
            }
          }
        }
      }
    }
  }`